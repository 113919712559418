<template>
  <div class="content-provider-detail">
    <div
      class="global-content-detail"
      :style="{ height: screenHeight + 'px' }"
    >
      <div class="content-information">
        <div class="content-img">
          <div class="content-backgorund">
            <v-skeleton-loader class="img-administrator"  type="image"></v-skeleton-loader>
          </div>
        </div>
        <v-row>
          <v-col cols="12" class="content-all">
            <v-row  class="content-text">
              <v-col cols="12">
                <p class="p-title">
                  <v-skeleton-loader
                    width="350"
                    type="text"
                  ></v-skeleton-loader>
                </p>
                <p class="p-occupation">
                  <v-skeleton-loader
                    width="150"
                    type="text"
                  ></v-skeleton-loader>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="5" xl="4">
                <div class="content-icon-text">
                  <v-icon class="icon-administrator">mdi-email-outline</v-icon>
                  <p class="p-text">
                    <v-skeleton-loader
                      width="150"
                      type="text"
                    ></v-skeleton-loader>
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="3" xl="4">
                <div class="content-icon-text">
                  <v-icon class="icon-administrator">mdi-phone-outline</v-icon>
                  <p class="p-text">
                    <v-skeleton-loader
                      width="150"
                      type="text"
                    ></v-skeleton-loader>
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="3" xl="4">
                <div class="content-icon-text">
                  <v-icon class="icon-administrator"
                    >mdi-calendar-blank-outline</v-icon
                  >
                  <p class="p-text">
                    <v-skeleton-loader
                      width="150"
                      type="text"
                    ></v-skeleton-loader>
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-divider class="divider-global mt-9"></v-divider>

      <div class="content-table-permission">
        <div dark class="global-content-table">
          <p class="text-title-permissions">Permisos administrativos</p>

          <v-data-table
            :headers="headers"
            :disable-sort="true"
            :items="desserts"
            :hide-default-footer="true"
            :items-per-page="5"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              'items-per-page-all-text': 'Todos',
            }"
            class="global-table"
          >
            <template v-slot:[`item.sDetail`]="{ item }">
              <div class="content-switch">
                <v-switch
                  class="switch-permission"
                  v-model="item.sDetail"
                  color="var(--primary-color-text-yellow)"
                  hide-details
                ></v-switch>
              </div>
            </template>
            <template v-slot:[`item.sAdmin`]="{ item }">
              <div class="content-switch">
                <v-switch
                  class="switch-permission"
                  v-model="item.sAdmin"
                  color="var(--primary-color-text-yellow)"
                  hide-details
                ></v-switch>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
      <v-divider class="divider-sidebar-global"></v-divider>

      <div class="content-btns-accions">
        <v-spacer></v-spacer>

        <div class="content-btn-primary">
          <v-skeleton-loader width="200" type="button"></v-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdministratorsDetail",
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      oAdminDetail: {},
      oContactInfo: {},
      headers: [
        {
          text: "Módulos",
          sortable: false,
          value: "sName",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Visualizar",
          sortable: false,
          value: "sDetail",
          align: "center",

          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Gestionar",
          value: "sAdmin",
          sortable: false,

          align: "center",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
      ],
      desserts: [
        {
          sName: "Proveedores",
          sDetail: false,
          sAdmin: false,
        },
        {
          sName: "Materias primas",
          sDetail: false,
          sAdmin: false,
        },
        {
          sName: "Administradores",
          sDetail: false,
          sAdmin: false,
        },
        {
          sName: "Perfiles de usuario",
          sDetail: false,
          sAdmin: false,
        },
      ],
    };
  },
  beforeMount() {
 
  },
  mounted() {
    this.matchHeight();
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    matchHeight() {
      if (this.$refs.searchAdministrator !== undefined) {
        this.heightMax = this.$refs.searchAdministrator.clientHeight;
        this.heightMax = this.heightMax + 123;
      }
    },
    handleResize: function () {
      this.matchHeight();
      if (window.innerWidth > 600) {
        this.screenHeight = window.innerHeight - this.heightMax;
      } else {
        this.screenHeight = window.innerHeight - this.heightMax;
      }
    },
  },
  computed: {
 
  },
  watch: {
 
  },
};
</script>

<style scoped>
.content-information {
  display: flex;
  margin-bottom: 15px;
}
.content-img {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  justify-content: center;
  justify-self: center;
  align-items: center;
  position: relative;
}
.content-backgorund {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.img-administrator {
  height: 160px;
  width: 160px;
  object-fit: cover;
  /* background-size: cover; */
  border-radius: 100%;
  border: var(--primary-color-border-text-field) solid 3px;
}

.button-add-img {
  width: 160px !important;
  height: 160px !important;
  background: transparent 0% 0% no-repeat padding-box !important;
  border: 2px dashed var(--primary-color-divider);
  border-radius: 100%;
  opacity: 1;
}

.button-delete-item {
  position: absolute;
  right: 90px;
  bottom: 5px;
  min-width: 30px !important;
  height: 30px !important;
  background-color: var(--primary-color-btn-neutral-red) !important;
  padding: 0px !important;
  border-radius: 10px;
}

.button-edit-item {
  position: absolute;
  right: 50px;
  bottom: 5px;
  min-width: 30px !important;
  height: 30px !important;
  background-color: var(--primary-color-btn-primary) !important;
  padding: 0px !important;
  border-radius: 10px;
}

.text-img-add {
  color: var(--primary-color-text) !important;
  font-family: "pop-Semibold";
  font-size: 9px;
  margin-bottom: 0px;
}

.text-title-permissions {
  text-align: start;
  letter-spacing: 4.8px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  /* margin-right: 10px; */
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}
.p-title {
  color: var(--primary-color-text);
  text-transform: uppercase;
  font-family: "pop-Semibold";
  font-size: 30px;
  margin-bottom: 0px;
}
.p-occupation {
  color: var(--primary-color-text);
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-bottom: 0px;
}
.content-icon-text {
  display: flex;
}
.icon-administrator {
  color: var(--primary-color-text) !important;
  margin-right: 15px;
}
.p-text {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.content-all {
  align-self: center !important;
}
.content-text-field {
  margin-top: 1px;
  margin-left: 10px;
}

.content-switch {
  display: flex;
  justify-content: center;
  width: 100%;
}
.switch-permission {
  margin: 0px;
  padding: 0px;
}
.content-btns-accions {
  display: flex;
  margin-top: 25px;
  width: 100% !important;
}
.content-btn-primary {
  width: 200px;
}
.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-information {
    display: block;
  }

  .content-img {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    justify-content: center;
    margin-bottom: 10px;
    display: flex;
  }

  .img-administrator {
    height: 150px;
    width: 160px;
    object-fit: cover;
    /* background-size: cover; */
    border-radius: 100%;
  }

  .p-title {
    color: var(--primary-color-text);
    text-transform: uppercase;
    font-family: "pop-Semibold";
    font-size: 30px;
    text-align: center;
    margin-bottom: 0px;
  }
  .p-occupation {
    color: var(--primary-color-text);
    font-family: "pop-Semibold";
    font-size: 14px;
    text-align: center;
    margin-bottom: 0px;
  }
  .content-icon-text {
    justify-content: center;
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-text-field {
    margin-top: 1px;
    margin-left: 10px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
